(function ($) {

    /**
     * Selector for the toggle elements.
     *
     * @type {string}
     */
    var selector = '[data-toggle="dropdown"]';

    var $toggle  = $(selector);

    /**
     * @param element
     * @constructor
     */
    var Dropdown = function(element) {
        // Initialize the toggle.
        $(element).on('click.dropdown', Dropdown.prototype.toggle);
    };

    /**
     * Open class used on the parent <li> element when the dropdown is open or closed.
     *
     * @type {string}
     */
    var OPEN_CLASS = 'dropdown-open';

    function closeMenus() {
        $toggle.removeClass(OPEN_CLASS);
    }

    Dropdown.prototype.toggle = function (e) {
        e.preventDefault();

        var $parent  = $(this).closest('li.dropdown');

        $parent
            .toggleClass(OPEN_CLASS)
            .siblings('li.dropdown')
            .removeClass(OPEN_CLASS);
    };

    Dropdown.prototype.keydown = function (e) {
        if (e && e.which === 27) {
            closeMenus();
        }
    };

    // Initialize the click events.
    $(selector).click(Dropdown.prototype.toggle);
        $(document).keydown(Dropdown.prototype.keydown);

})(jQuery);
