(function ($) {

    /**
     * @constructor
     */
    function Videos() {
        this.$video         = $('iframe[src*="youtube"]');
        this.$videoFace     = $('.video-play');
        this.videoIsPlaying = false;
        this.isPlayable     = false;

        if (this.$video.length) {
            this.addScripts();
        }

        this.$videoFace.on('click', $.proxy(this.playVideo, this));

        $(document).on('keydown', $.proxy(this.keypressEventHandler, this));
    }

    Videos.prototype.keypressEventHandler = function (e) {
        if (this.videoIsPlaying && e.which === 27) {
            this.stopVideo();
        }
    };

    Videos.prototype.addScripts = function () {
        var script = $('<script />', {
            src: 'https://www.youtube.com/iframe_api'
        });

        script.insertBefore($('script').first());
    };

    Videos.prototype.hideVideoFace = function () {
        this.$videoFace.fadeOut(200);
    };

    Videos.prototype.showVideoFace = function() {
        this.$videoFace.fadeIn(200);
    };

    /**
     * Set up the YT.Player object and get the events set up for playing.
     */
    Videos.prototype.playVideo = function (e) {
        e.preventDefault();

        var self = this;

        this.hideVideoFace();

        if (! this.player) {
            this.player = new YT.Player(this.$video.attr('id'), {
                events: {
                    onReady: $.proxy(self.playerReadyEventHandler, self),
                    onStateChange: $.proxy(self.stateChangeHandler, self)
                }
            });
        } else {
            this.player.seekTo(0).playVideo();
        }
    };

    Videos.prototype.stateChangeHandler = function(event) {
        if (event.data === YT.PlayerState.PLAYING) {
            this.hideVideoFace();

            this.videoIsPlaying = true;
        }

        if (event.data === YT.PlayerState.ENDED) {
            this.videoIsPlaying = false;

            this.showVideoFace();
        }
    };

    /**
     * Play the video.
     *
     * @param event
     */
    Videos.prototype.playerReadyEventHandler = function (event) {
        event.target.playVideo();

        this.videoIsPlaying = true;
    };

    $(document).ready(new Videos());
})(jQuery);

