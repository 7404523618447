var $ = window.jQuery;

function init() {
    $('.team-member-bio-link').magnificPopup({
        type: 'inline',
        gallery: { enabled: true }
    });
};

$(document).ready(init);
